import React, { ReactNode } from 'react';
import { History, UnregisterCallback } from 'history';
import { PageHeader } from '../types/util.d';
import { HTTPError } from '../api/ErrorHandling';

interface IErrorBoundaryProps {
  history: History<History.LocationState>
}
interface IErrorBoundaryState {
  hasError: boolean;
  errorData: Error | null;
}
export default class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  private historyUnlistener?: UnregisterCallback;
  private errorMsgs: Array<string | ReactNode>;
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      errorData: null
    };

    this.errorMsgs = [];

    // Client errors
    this.errorMsgs[400] = 'I think there was something wrong with that last request. This could be something on our end, though.';
    this.errorMsgs[401] = 'You might need to log in before you try that again.';
    this.errorMsgs[403] = 'Nope, you\'re not allowed here. You\'re gonna have to go back and try something else.';
    this.errorMsgs[404] = 'The page you tried to access doesn\'t appear to exist.';
    this.errorMsgs[405] = 'Well, that didn\'t work like we were hoping it would. This might be something on our end.';
    this.errorMsgs[410] = 'There used to be a thing, here, we swear. But, it\'s gone now, so you might want to go back and look for it elsewhere.';
    this.errorMsgs[429] = 'I think you\'ve got too much happening all at once! Slow it down, and try again later.';

    // Server errors
    this.errorMsgs[500] = 'Whoops! That request went badly. This is probably a problem on our end, so let us know, would ya?';
    this.errorMsgs[501] = 'One day, this is gonna be a thing. But we\'re not there yet.';

    // Joke errors
    this.errorMsgs[108] = 'Four, eight, fifteen, sixteen, twenty-three, forty-two. We failed to push the button.';
    this.errorMsgs[200] = <>If you got this error, either something went wrong with our site settings, or you were <strong>trying</strong> to get this error on purpose.</>;
    this.errorMsgs[418] = <>We're sorry, but the website you tried to access is not, in fact, a <abbr title="If, in fact, it was a teapot, there is a high likelihood of it dispensing a liquid which was almost, but not quite, entirely unlike tea.">teapot</abbr>.</>;
    this.errorMsgs[420] = 'Okay, at this point, I\'m pretty sure you\'re just fishing for these HTTP messages. This one really only existed on Twitter early on.';
    this.errorMsgs[666] = 'We\'re sorry, but our Satan summoning service is out of order. Also, it doesn\'t exist. Move along, now.';
  }

  componentDidMount() {
    this.historyUnlistener = this.props.history.listen(this.resetBoundary);
  }

  componentWillUnmount() {
    if (this.historyUnlistener) this.historyUnlistener();
  }

  static getDerivedStateFromError(error: Error | null) {
    return {
      hasError: error,
      errorData: error
    };
  }

  resetBoundary = (listener: History.LocationState) => {
    this.setState({
      hasError: false,
      errorData: null
    });
  }

  render() {
    let title = 'Error';
    let errorDisp = null;
    if (this.state.errorData instanceof HTTPError) {
      const code = this.state.errorData.getCode();
      title = `Error ${code}`;
      if (this.errorMsgs[code]) errorDisp = <p>{this.errorMsgs[code]}</p>;
    }
    if (this.state.hasError) {
      return <>
        <PageHeader error={true}>{title}</PageHeader>
        {errorDisp}
        <p>{(this.state.errorData as Error).message}</p>
      </>;
    }
    else return this.props.children;
  }
}