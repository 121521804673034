import React from 'react';
import { Link } from 'react-router-dom';
import { IPixAuthenticationState } from '../types/api.d';

interface ISidebarProps {
  loginStatus?: IPixAuthenticationState | null;
}
const SidebarContent: React.FC<ISidebarProps> = ({ loginStatus }: ISidebarProps) => {
  const loginDisplay = loginStatus && loginStatus.loggedIn
    ? <>Logged in as {loginStatus.username}</>
    : <>Not logged in</>;
  return (
    <>
      <b>Sidebar content</b><br />
      {loginDisplay}<br />
      <Link to='/game/abadox' className='impact linkButton'>Abadox</Link><br />
      <Link to='/game/actraiser' className='impact linkButton'>ActRaiser</Link><br />
      <Link to='/game/doesnotexist' className='impact linkButton'>Cause havoc</Link><br />
    </>
  );
}

export default SidebarContent;
