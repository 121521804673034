import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet-async';
import Sidebar from 'react-sidebar';
import { Switch, Route, useHistory } from 'react-router-dom';
import { History, UnregisterCallback } from 'history';
import cogoToast from 'cogo-toast';

import Axios, { AxiosError } from 'axios';

import { faBars, faArrowLeft, faHome, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import SidebarContent from './SidebarContent';
import Loading from './Loading';
import ErrorBoundary from './ErrorBoundary';
import { LoadingProvider } from '../contexts/LoadingContext';
import { HTTPError } from '../api/ErrorHandling';

import smallLogo from '../assets/smalllogo.png';
import logo from '../assets/logofornow.png';

import '../css/App.css';
import 'reactjs-navbar/dist/index.css';
import { PixPermissionState, IPixAuthenticationState, IPixApiData, GenericObject } from '../types/api.d';
import { PermissionProvider } from '../contexts/PermissionContext';
const Navbar = require('reactjs-navbar').default;

const Catpaw = lazy(() => import('./Catpaw'));
const Admin = lazy(() => import('./routes/Admin'));
const Home = lazy(() => import('./routes/Home'));
const Series = lazy(() => import('./routes/Series'));
const Video = lazy(() => import('./routes/Video'));
const Verify = lazy(() => import('./routes/Verify'));

const mql = window.matchMedia(`(min-width: 800px)`);
const { localStorage, location } = window;

const DeadRoute: React.FC = () => { throw new HTTPError(404, 'Path not found') };

interface IAppProps {
  history: History<History.LocationState>
}
interface IAppState {
  sidebarDocked?: boolean;
  sidebarOpen?: boolean;
  isLoading: number;
  dialogPath: string | null;
  loginStatus?: Readonly<IPixAuthenticationState>;
}
class AppInner extends React.Component<IAppProps, IAppState> {
  private historyUnlistener?: UnregisterCallback;
  constructor(props: any) {
    super(props);
    let initialState: IAppState = {
      sidebarDocked: mql.matches,
      sidebarOpen: mql.matches,
      isLoading: 0,
      dialogPath: null
    }

    const token = localStorage.getItem('pixtoken');
    if (token) for (let method of ['get', 'post', 'put', 'delete'])
      Axios.defaults.headers[method]['Authorization'] = `Bearer ${token}`;
    else initialState.loginStatus = { loggedIn: false }

    window.addEventListener('storage', (e: StorageEvent) => {
      if (e.key === 'pixtoken') location.reload();
    });

    if (process.env.REACT_API_LOCAL === 'true')
      for (let method of ['get', 'post', 'put', 'delete'])
        Axios.defaults.headers[method]['Access-Control-Request-Method'] = method.toUpperCase();

    this.state = initialState;
  }

  componentDidMount() {
    mql.addEventListener('change', this.mediaQueryChanged);
    this.historyUnlistener = this.props.history.listen(this.onLocationChanged);
    Axios.get(`${process.env.REACT_APP_BASE_API_PATH}auth/login`)
      .then(e => {
        this.setState({
          loginStatus: (e.data as IPixApiData<IPixAuthenticationState>).data
        });
      })
      .catch((e: AxiosError) => {
        if (Object.keys(localStorage).includes('pixtoken')) {
          localStorage.removeItem('pixtoken');
          location.reload();
        }
      });

    if (Object.keys(localStorage).includes('pixlogout'))
      cogoToast.warn(localStorage.getItem('pixlogout'), {
        position: 'bottom-right',
        heading: 'Logged out'
      }).then(() => localStorage.removeItem('pixlogout'));

    if (!Object.keys(localStorage).includes('pixprivacy'))
      cogoToast.info('This is the development site for LowBiasGaming. This notification will advise new visitors of our privacy policy, and have a link to it.', {
        position: 'bottom-right',
        heading: 'Welcome to LowBiasGaming!',
        hideAfter: 30
      }).then(() => localStorage.setItem('pixprivacy', (Date.now().valueOf() / 1000).toString()));
    else if (isNaN(parseFloat(localStorage.getItem('pixprivacy')!))) localStorage.setItem('pixprivacy', (Date.now().valueOf() / 1000).toString());
  }

  componentWillUnmount() {
    mql.removeEventListener('change', this.mediaQueryChanged);
    if (this.historyUnlistener) this.historyUnlistener();
  }

  onSetSidebarOpen = (open: boolean) => {
    this.setState({ sidebarOpen: open });
  }

  onLocationChanged = () => {
    console.debug(`Route changed: ${window.location.pathname}`);
    const pathComponents = window.location.pathname.substr(1).split('/');

    if (pathComponents[0] === 'ports') {
      this.props.history.replace(`/game/${pathComponents.slice(1).join('/')}`)
    }
  }

  toggleSidebar = () => this.setState({ sidebarOpen: !this.state.sidebarOpen });

  setLoading = (isLoading: boolean) =>
    this.setState({ isLoading: Math.max(this.state.isLoading + (isLoading ? 1 : -1), 0) });

  mediaQueryChanged = () =>
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });

  openDialog = (dialogPath: string) => this.setState({ dialogPath });
  resetDialog = () => this.setState({ dialogPath: null });

  checkPermission = (perm: PixPermissionState, ...path: string[]): boolean | undefined => {
    if (!this.state.loginStatus || !this.state.loginStatus.perms) return undefined;

    const getPerms = (permBranch: GenericObject, getBranch: string): GenericObject | number => permBranch[getBranch];

    if (path.length === 0) return undefined;
    else if (path[0] === '__loggedin') return this.state.loginStatus.loggedIn;
    else if (path[0] === '__username') return path.length > 1 ? path[1] === this.state.loginStatus.username : undefined;
    else if (path[0] === '__userid') return path.length > 1 ? path[1] === this.state.loginStatus.userId : undefined;
    else {
      let perms: number | GenericObject = this.state.loginStatus.perms;
      for (const branch of path) {
        if (typeof perms === 'number') return undefined;
        perms = getPerms(perms, branch);
      }
      return (typeof perms === 'number') ? (perms & perm) === perm : undefined;
    }
  }

  render() {
    const { loginStatus } = this.state;
    if (loginStatus === undefined) return (
      <code className='openSetup'>
        Just a second as we get things set up...
      </code>
    );
    return (
      <div className="App">
        <Helmet defaultTitle={`${process.env.REACT_APP_SITE_TITLE} | We Play Games`} titleTemplate={`${process.env.REACT_APP_SITE_TITLE} | %s`}>
          <title>We Play Games</title>
          <meta name='description' content='LowBiasGaming, HI_SPEED DUDES' />
        </Helmet>
        <Sidebar
          sidebarId='sidebar'
          sidebar={<SidebarContent loginStatus={this.state.loginStatus} />}
          open={!this.state.sidebarDocked && this.state.sidebarOpen}
          docked={this.state.sidebarDocked && this.state.sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
        >
          <Navbar
            logo={mql.matches ? logo : smallLogo}
            isLoading={this.state.isLoading > 0}
            // helpCallback={() => alert('Help win!')}
            menuItems={[
              {
                title: 'Sidebar',
                icon: this.state.sidebarOpen ? faArrowLeft : faBars,
                isAuth: true,
                onClick: this.toggleSidebar
              },
              {
                title: 'Home',
                icon: faHome,
                isAuth: true,
                onClick: () => this.props.history.push('/')
              },
              {
                title: 'Log in',
                icon: faSignInAlt,
                isAuth: !loginStatus || !loginStatus.loggedIn,
                onClick: () => this.openDialog('login')
              },
              {
                title: 'Log out',
                icon: faSignOutAlt,
                isAuth: loginStatus && loginStatus.loggedIn,
                onClick: () => {
                  localStorage.removeItem('pixtoken');
                  localStorage.setItem('pixlogout', 'Logged out successfully');
                  location.reload();
                }
              }
            ]}
          />
          <div id='sitebody'>
            <LoadingProvider value={this.setLoading}>
              <PermissionProvider value={this.checkPermission}>
                <Suspense fallback={<Loading quiet />}>
                  <Catpaw path={this.state.dialogPath} resetDialog={this.resetDialog} />
                </Suspense>
                <ErrorBoundary history={this.props.history}>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route path='/admin' component={Admin} />
                      <Route path='/verify/:un/:code' component={Verify} />
                      <Route path={[
                        '/video/:sn/:ord',
                        '/video/:sn'
                      ]} component={Video} />
                      <Route path='/ports' component={Loading} />
                      <Route path={[
                        '/game/:sn/:plat',
                        '/game/:sn'
                      ]}>
                        <Series namespace='game' />
                      </Route>
                      <Route exact path='/' component={Home} />
                      <Route component={DeadRoute} />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </PermissionProvider>
            </LoadingProvider>
          </div>
        </Sidebar>
      </div>
    );
  }
}

const App: React.FC = () => {
  const history = useHistory();
  return <AppInner history={history} />
}

export default App;
