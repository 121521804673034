import React from 'react';
import { IPixApiTagNamespaceData, PixPermissionState } from './api.d';

export type LoadingCallback = (isLoading: boolean) => void;
export type PermissionCallback = (perm: PixPermissionState, ...branch: string[]) => boolean | undefined;

export interface ITagContext {
  RegisterTags: (subject: string[], tags: IPixApiTagNamespaceData[]) => void;
  UnregisterTags: (subject: string[]) => void;
}

interface IPageHeaderProps {
  error?: boolean;
}
export const PageHeader: React.FC<IPageHeaderProps> = (props) => (
  <>
    <header className={`impact pageHeader${props.error ? ' error' : ''}`}>{props.children}</header>
    <div>&nbsp;</div>
  </>
);

interface IPageSectionProps {
  className?: string
}
export const PageSection: React.FC<IPageSectionProps> = (props) =>
  <section className={`pageSection${props.className ? ` ${props.className}` : ''}`}>{props.children}</section>;