import { AxiosError } from 'axios';
import { IPixApiData } from '../types/api.d';
const { localStorage, location } = window;

export class HTTPError extends Error {
  private status: number;
  private previous?: Error;

  constructor(status: number, message?: string, previous?: Error) {
    super(message);
    this.status = status;
    this.previous = previous;
  }

  getCode(): number { return this.status; }
  getPrevious(): Error | undefined { return this.previous; }
}

export function APIErrorCatcher<T>(e: AxiosError<IPixApiData<T>>): IPixApiData<T> {
  if (e.response) {
    throw new HTTPError(e.response.status, e.response.data ? e.response.data.error!.error_message : 'No error data was returned.', e);
  } else throw e;
}

export function APILogoutCatcher<T>(r: IPixApiData<T>): IPixApiData<T> {
  if (r.logout) {
    localStorage.removeItem('pixtoken');
    localStorage.setItem('pixlogout', r.logout);
    location.reload();
  }
  return r;
}