import React, { useContext, useEffect } from 'react';
import { LoadingContext } from '../contexts/LoadingContext'

interface ILoadingProps {
  quiet?: boolean
  children?: React.ReactNode
}

const Loading: React.FC<ILoadingProps> = ({ quiet, children }: ILoadingProps) => {
  const loadingCallback = useContext(LoadingContext);
  useEffect(() => {
    if (loadingCallback) {
      loadingCallback(true);
      return () => { loadingCallback(false); }
    }
  });

  return quiet ? null : <div className='loading'>{children || 'Loading'}</div>;
}

export default Loading;
